import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from '../components/slider';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import Image from '../components/image';

import downChevron from '../assets/svg/down-chevron.svg';
import moment from 'moment';




class BlogDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }


    }



    render() {

        return (
            <div className="about-us-wrap">
                <section className-name="el_MWt_UkcEbY" className="el_MWt_UkcEbY">
                    <div className-name="el_nxSTNAI73a" className="el_nxSTNAI73a container">
                        <div className-name="el_xXSSMBLR64" className="el_xXSSMBLR64 row">
                            <div className-name="el_CEQDddamsC" className="el_CEQDddamsC col-lg-12">
                                <h1 className-name="el_36aob6dvPA" className="el_36aob6dvPA">{'Novosti'.translate(this.props.lang)}</h1>
                                <ul className-name="el_P3NocKyAEA" className="el_P3NocKyAEA">
                                    <li className-name="el_Jl1PjCRvxx" className="el_Jl1PjCRvxx">{'Početna'.translate(this.props.lang)}</li>
                                    <li className-name="el_Jl1PjCRvxx" className="el_Jl1PjCRvxx">{'Novosti'.translate(this.props.lang)}</li>
                                </ul>
                            </div>
                            <div className="col-lg-8 blog-detail">
                                <h2>{Object.translate(this.state.data, 'title', this.props.lang)}</h2>
                                <p className="date-time">
                                    {moment.unix(this.state.data && this.state.data.timestamp).format('DD.MM.YYYY.')}
                                    <span>|</span>
                                    {moment.unix(this.state.data && this.state.data.timestamp).format('HH:mm')} h
    
                                </p>
                                <img src={Object.get(this.state.data, 'image')} />
                                <div className="content" dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'content', this.props.lang) }}></div>
                            </div>
                            <div className="col-lg-4 blog-detail-latest">
                                {
                                    this.state.latestBlog && this.state.latestBlog.map((item, idx) => {
                                        return (

                                            <article class-name="el_s_SzWof5q1" className="el_s_SzWof5q1 latest-blog-item">
                                                <Link lang={this.props.lang} to={`/novosti/${Object.translate(item, 'alias', this.props.lang)}`}>  <img class-name="el_xSO39kUVy1" className="el_xSO39kUVy1" src={item.image} /> </Link>
                                                <div class-name="el_ujFQKxiIki" className="el_ujFQKxiIki">
                                                    <p class-name="el_yLy6C_nuHV" className="el_yLy6C_nuHV">{moment.unix(item.timestamp).format('DD')}</p>
                                                    <div class-name="el_47Qfk6MDfc" className="el_47Qfk6MDfc"></div>
                                                    <p class-name="el_keLZ5Jwi1W" className="el_keLZ5Jwi1W">{moment.unix(item.timestamp).format('MMM YYYY')}</p>
                                                </div>

                                                <div class-name="el_7QHzULcE8O" className="el_7QHzULcE8O">
                                                    <div class-name="el_RZ68j58P7w" className="el_RZ68j58P7w">
                                                        <Link lang={this.props.lang} to={`/novosti/${Object.translate(item, 'alias', this.props.lang)}`}> <h6 class-name="el_Ab1FpyxUOn" className="el_Ab1FpyxUOn">{Object.translate(item, 'title', this.props.lang)}</h6></Link>
                                                        <p class-name="el_wVA0h2JrGD" className="el_wVA0h2JrGD">{Object.translate(item, 'shortDescription', this.props.lang)}</p><Link lang={this.props.lang} class-name="el_xAyLrx9pbw"
                                                            className="el_xAyLrx9pbw" to={`/novosti/${Object.translate(item, 'alias', this.props.lang)}`}>{'Detaljnije'.translate(this.props.lang)}</Link>
                                                    </div>
                                                </div>
                                            </article>

                                        )
                                    })
                                }

                            </div>



                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Page(BlogDetailPage);