export const langs = {
    "ba": {
        "Marka vozila": "Marka vozila",
        "Tip vozila": "Tip vozila",
        "Oblik karoserije": "Oblik karoserije",
        "Broj vrata": "Broj vrata",
        "Vrsta mjenjača": "Vrsta mjenjača",
        "Snaga motora (kW)": "Snaga motora (kW)",
        "Zapremina motora (m3=cbm)": "Zapremina motora (m3=cbm)",
        "Pogon": "Pogon",
        "Vrsta goriva": "Vrsta goriva",
        "Emisioni standard": "Emisioni standard",
        "Datum prve registracije": "Datum prve registracije",
        "Manuelni": "Manuelni",
        "Automatik": "Automatik",
        "Poluautomatik": "Poluautomatik",
        "Dizel": "Dizel",
        "Benzin": "Benzin",
        "Plin": "Plin",
        "Hibrid": "Hibrid",
        "Elektro": "Elektro",
        "Prednji": "Prednji",
        "Zadnji": "Zadnji",
        "Sva četiri": "Sva četiri",
        "Kupe": "Kupe",
        "Limuzina": "Limuzina",
        "Karavan": "Karavan",
        "Produžena limuzina": "Produžena limuzina",
        "Višenamjenski zatvoreni putnički automobil": "Višenamjenski zatvoreni putnički automobil",
        "Specijalni zatvoreni putnički automobil": "Specijalni zatvoreni putnički automobil",
        "Kupe kabriolet": "Kupe kabriolet",
        "Limuzina kabriolet": "Limuzina kabriolet",
        "Sportski automobil": "Sportski automobil",

        "Informativni obračun carinskih dadžbina za uvoz vozila u BiH": "Informativni obračun carinskih dadžbina za uvoz vozila u BiH",
        "Uvoz vozila": "Uvoz vozila",
        "Nase usluge": "Naše usluge",
        "Budite slobodni da nas kontaktirate. Odgovorit ćemo vam u što kraćem roku.":"Budite slobodni da nas kontaktirate. Odgovorit ćemo vam u što kraćem roku.",
        "Usluge transporta koje nudimo": "Usluge transporta koje nudimo",
        "KOMPANIJA OSNOVANA": "KOMPANIJA OSNOVANA",
        "ZEMALJA POSLUJEMO": "ZEMALJA POSLUJEMO",
        "POŠILJKI DOSTAVLJENO": "POŠILJKI DOSTAVLJENO",
        "Aktuelnosti": "Aktuelnosti",
        "Novosti": "Novosti",
        "i obavještenja": "i obavještenja",
        "Aktuelnosti vezane za našu firmu": "Aktuelnosti vezane za našu firmu",
        "Telefoni:": "Telefoni:",
        "Radno vrijeme:": "Radno vrijeme:",
        "Detaljnije": "Detaljnije",
        "O nama": "O nama",
        "Početna": "Početna",
        "Usluge": "Usluge",
        "Navigacija": "Navigacija",
        "Kontakt": "Kontakt",
        "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.": "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.",
        "INFORMACIJE": "INFORMACIJE",
        "Top TLS d.o.o. korisni podaci": "Top TLS d.o.o. korisni podaci",
        "ID broj": "ID broj",
        "PDV broj:": "PDV broj:",
        "Registracija:": "Registracija:",
        "Općinski sud u Sarajevu": "Općinski sud u Sarajevu",
        "Transakcijski račun – UniCredit bank": "Transakcijski račun – UniCredit bank",
        "Devizni račun (IBAN) – UniCredit bank": "Devizni račun (IBAN) – UniCredit bank",
        "Devizni račun (SWIFT):": "Devizni račun (SWIFT):",
        "Ime": "Ime",
        "Prezime": "Prezime",
        "Telefon": "Telefon",
        "E-mail": "E-mail",
        "Naslov": "Naslov",
        "Vaša poruka": "Vaša poruka"
    },
    "en": {
        "Marka vozila": "Vehicle brand",
        "Tip vozila": "Vehicle type",
        "Oblik karoserije": "Body type",
        "Broj vrata": "Number of doors",
        "Vrsta mjenjača": "Gear",
        "Snaga motora (kW)": "Engine power (kW)",
        "Zapremina motora (m3=cbm)": "Engine capacity (m3=cbm)",
        "Pogon": "Drive",
        "Vrsta goriva": "Fuel type",
        "Emisioni standard": "Emissions standard",
        "Datum prve registracije": "First registration date",
        "Manuelni": "Manual",
        "Automatik": "Automatic",
        "Poluautomatik": "Semi-automatic",
        "Dizel": "Diesel",
        "Benzin": "Gasoline",
        "Plin": "Gas",
        "Hibrid": "Hybrid",
        "Elektro": "Electric",
        "Emisioni standard": "Emissions standard",
        "Prednji": "Front",
        "Zadnji": "Rear",
        "Sva četiri": "Four",

        "Kupe": "Coupe",
        "Limuzina": "Sedan",
        "Karavan": "Station wagon",
        "Produžena limuzina": "Big sedan",
        "Višenamjenski zatvoreni putnički automobil": "Multi-purpose closed passenger car",
        "Specijalni zatvoreni putnički automobil": "Special closed passenger car",
        "Kupe kabriolet": "Coupe convertible",
        "Limuzina kabriolet": "Sedan convertible",
        "Sportski automobil": "Sport car",

        "Informativni obračun carinskih dadžbina za uvoz vozila u BiH": "Custom and VAT fee calculation for import vehicle in BiH",
        "Uvoz vozila": "Import vehicles",
        "Budite slobodni da nas kontaktirate. Odgovorit ćemo vam u što kraćem roku.":"Fell free to contact us by form",
        "Nase usluge": "Our services",
        "Usluge transporta koje nudimo": "Transport services we offer",
        "KOMPANIJA OSNOVANA": "COMPANY ESTABLISHED",
        "ZEMALJA POSLUJEMO": "COUNTRIES WE BUSINESS",
        "POŠILJKI DOSTAVLJENO": "SHIPMENTS DELIVERED",
        "Aktuelnosti": "Current news",
        "Novosti": "News",
        "i obavještenja": "and announcements",
        "Aktuelnosti vezane za našu firmu": "News related to our company",
        "Telefoni:": "Telephones:",
        "Radno vrijeme:": "Working hours:",
        "Detaljnije": "More details",
        "O nama": "About Us",
        "Početna": "Home",
        "Usluge": "Services",
        "Uvoz vozila": "Import of vehicles",
        "Navigacija": "Navigation",
        "Kontakt": "Contact",
        "Vaš upit": "Your inquiry",
        "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.": "Feel free to contact us. We will answer you as soon as possible.",
        "INFORMACIJE": "INFORMATION",
        "Top TLS d.o.o. korisni podaci": "Top TLS d.o.o. useful data",
        "ID broj": "ID number",
        "PDV broj:": "VAT number:",
        "Registracija:": "Registration:",
        "Općinski sud u Sarajevu": "Municipal Court in Sarajevo",
        "Transakcijski račun – UniCredit bank": "Transaction account - UniCredit bank",
        "Devizni račun (IBAN) – UniCredit bank": "Foreign currency account (IBAN) - UniCredit bank",
        "Devizni račun (SWIFT):": "Foreign currency account (SWIFT):",
        "Ime": "Name",
        "Prezime": "Surname",
        "Telefon": "Phone",
        "E-mail": "E-mail",
        "Naslov": "Title",
        "Vaša poruka": "Your message",
        "POŠALJI": "SEND",
        "Vaša poruka je poslata, očekujte odgovor ubrzo.": "Your message has been sent, expect a reply soon.",
        "Kontakt:": "Contact:"

    }
}