import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Textarea from './fields/textarea';
import rightChevron from '../../assets/svg/right-chevron.svg';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    meta: { touched, error },
    type
}) => (

        <Text
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            type={type}
            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }



    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(pristine, submitting);

        return (
            <form class-name="el_Ta14Yd8BZM" className="el_Ta14Yd8BZM" onSubmit={handleSubmit}>
                <div class-name="el_FsCMFzwIV5" className="el_FsCMFzwIV5 row">
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-6">
                        <Field
                            name="firstName"
                            component={renderTextField}
                            placeholder={"Ime".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-6">
                        <Field
                            name="lastName"
                            component={renderTextField}
                            placeholder={"Prezime".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-6">

                        <Field
                            name="phoneNumber"
                            component={renderTextField}
                            placeholder={"Telefon".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-6">

                        <Field
                            name="email"
                            component={renderTextField}
                            placeholder={"E-mail".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-12">

                        <Field
                            name="subject"
                            component={renderTextField}
                            placeholder={"Naslov".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-12">
                        <Field
                            name="message"
                            component={renderTextareaField}
                            placeholder={"Vaša poruka".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>

                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-12">

                        {this.props.loading ?
                            null
                            :
                            <button className="button">{'POŠALJI'.translate(this.props.lang)}</button>
                        }
                    </div>
                    
    
                </div>
            </form>

        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
