

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { langs } from './langs';
import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import ReactGA from 'react-ga';

const rootReducer = combineReducers({
  form: formReducer
});

const store = createStore(rootReducer)



function generateAlias(str) {
  str = str.toLowerCase();
  str = str.replace(/ä/g, 'a');
  str = str.replace(/ö/g, 'o');
  str = str.replace(/ü/g, 'u');
  str = str.replace(/ß/g, 'b');

  str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
  str = str.replace(/-+/g, '-');

  return str;
}

if (String.prototype.generateAlias == null) {
  String.prototype.generateAlias = function () {
    return generateAlias(this);
  }
}


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['ba'];
}


Object.get = function (o, s) {
  console.log(o, s)
  if (!o) {
    return null;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {

   /* if (!localStorage.translate){
      localStorage.translate = JSON.stringify({
        'ba': {
 
        },
        'en': {
 
        }
      });
    }
 
    let obj = JSON.parse(localStorage.translate);
    obj.en[this] = this;
    obj.ba[this] = this;

    localStorage.translate = JSON.stringify(obj);
    
    return this;*/

    if (langs[lang] && langs[lang][this])
      return langs[lang][this];
    else return this;
  }
}

var svgs = [
  {
    x: 138,
    y: 233,
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="23.588" height="22.856" viewBox="0 0 23.588 22.856">
                  <g id="Polygon_1" data-name="Polygon 1" transform="translate(0 9) rotate(-30)" fill="none">
                  <path d="M9,0l9,16H0Z" stroke="none"/>
                  <path d="M 9 4.079453468322754 L 3.419692039489746 14 L 14.58030700683594 14 L 9 4.079453468322754 M 9 0 L 18 16 L 0 16 L 9 0 Z" stroke="none" fill="#00aeea"/>
                  </g>
              </svg>
    `
  },


  {
    x: 235,
    y: 648,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="21.856" height="21.856" viewBox="0 0 21.856 21.856">
      <g id="Rectangle_57" data-name="Rectangle 57" transform="translate(8) rotate(30)" fill="none" stroke="#fac54b" stroke-width="2">
        <rect width="16" height="16" stroke="none"/>
        <rect x="1" y="1" width="14" height="14" fill="none"/>
      </g>
    </svg>
    
      `
  },

  {
    x: 135,
    y: 1141,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="18.372" height="18.371" viewBox="0 0 18.372 18.371">
      <path id="Union_2" data-name="Union 2" d="M6.5,15V8.5H0v-2H6.5V0h2V6.5H15v2H8.5V15Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 3.882)" fill="#00aeea"/>
    </svg>
    
      `
  },

  {
    x: 142,
    y: 1472,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="23.588" height="22.856" viewBox="0 0 23.588 22.856">
      <g id="Polygon_4" data-name="Polygon 4" transform="translate(0 9) rotate(-30)" fill="none">
        <path d="M9,0l9,16H0Z" stroke="none"/>
        <path d="M 9 4.079453468322754 L 3.419692039489746 14 L 14.58030700683594 14 L 9 4.079453468322754 M 9 0 L 18 16 L 0 16 L 9 0 Z" stroke="none" fill="#f0bd48"/>
      </g>
    </svg>
    
      `
  },


  {
    x: 201,
    y: 2021,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="21.856" height="21.856" viewBox="0 0 21.856 21.856">
      <g id="Rectangle_66" data-name="Rectangle 66" transform="translate(21.856 13.856) rotate(150)" fill="#fff" stroke="#00aeea" stroke-width="2">
        <rect width="16" height="16" stroke="none"/>
        <rect x="1" y="1" width="14" height="14" fill="none"/>
      </g>
    </svg>
          
      `
  },


  {
    x: 223,
    y: 2517,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="18.372" height="18.372" viewBox="0 0 18.372 18.372">
      <path id="Union_5" data-name="Union 5" d="M6.5,15V8.5H0v-2H6.5V0h2V6.5H15v2H8.5V15Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 3.882)" fill="#fac54b"/>
    </svg>
          
      `
  },

  {
    x: 130,
    y: 2980,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="23.588" height="22.856" viewBox="0 0 23.588 22.856">
      <g id="Polygon_5" data-name="Polygon 5" transform="translate(8) rotate(30)" fill="none">
        <path d="M9,0l9,16H0Z" stroke="none"/>
        <path d="M 9 4.079453468322754 L 3.419692039489746 14 L 14.58030700683594 14 L 9 4.079453468322754 M 9 0 L 18 16 L 0 16 L 9 0 Z" stroke="none" fill="#00aeea"/>
      </g>
    </svg>
    
      `
  },





  {
    x: 263,
    y: 240,
    right: true,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="18.372" height="18.372" viewBox="0 0 18.372 18.372">
      <path id="Union_3" data-name="Union 3" d="M6.5,15V8.5H0v-2H6.5V0h2V6.5H15v2H8.5V15Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 3.882)" fill="#fac54b"/>
    </svg>
    
      `
  },

  {
    x: 134,
    y: 553,
    right: true,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="21.856" height="21.856" viewBox="0 0 21.856 21.856">
      <g id="Rectangle_62" data-name="Rectangle 62" transform="translate(8) rotate(30)" fill="none" stroke="#00aeea" stroke-width="2">
        <rect width="16" height="16" stroke="none"/>
        <rect x="1" y="1" width="14" height="14" fill="none"/>
      </g>
    </svg>
    
      `
  },

  {
    x: 115,
    y: 1031,
    right: true,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="23.589" height="22.856" viewBox="0 0 23.589 22.856">
      <g id="Polygon_2" data-name="Polygon 2" transform="translate(0 9) rotate(-30)" fill="none">
        <path d="M9,0l9,16H0Z" stroke="none"/>
        <path d="M 9 4.079453468322754 L 3.419692039489746 14 L 14.58030700683594 14 L 9 4.079453468322754 M 9 0 L 18 16 L 0 16 L 9 0 Z" stroke="none" fill="#00aeea"/>
      </g>
    </svg>
    
      `
  },
  {
    x: 160,
    y: 1400,
    right: true,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="22.627" height="22.627" viewBox="0 0 22.627 22.627">
      <g id="Rectangle_63" data-name="Rectangle 63" transform="translate(0 11.314) rotate(-45)" fill="#fff" stroke="#fac54b" stroke-width="2">
        <rect width="16" height="16" stroke="none"/>
        <rect x="1" y="1" width="14" height="14" fill="none"/>
      </g>
    </svg>
    
      `
  },
  {
    x: 80,
    y: 1879,
    right: true,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="23.589" height="22.856" viewBox="0 0 23.589 22.856">
      <g id="Polygon_3" data-name="Polygon 3" transform="translate(8) rotate(30)" fill="none">
        <path d="M9,0l9,16H0Z" stroke="none"/>
        <path d="M 9 4.079453468322754 L 3.419692039489746 14 L 14.58030700683594 14 L 9 4.079453468322754 M 9 0 L 18 16 L 0 16 L 9 0 Z" stroke="none" fill="#00aeea"/>
      </g>
    </svg>
    
      `
  },
  {
    x: 201,
    y: 2649,
    right: true,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="18.372" height="18.372" viewBox="0 0 18.372 18.372">
      <path id="Union_4" data-name="Union 4" d="M6.5,15V8.5H0v-2H6.5V0h2V6.5H15v2H8.5V15Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 3.882)" fill="#fac54b"/>
    </svg>
    
      `
  },
  {
    x: 119,
    y: 3136,
    right: true,
    svg: `
      <svg xmlns="http://www.w3.org/2000/svg" width="21.856" height="21.856" viewBox="0 0 21.856 21.856">
      <g id="Rectangle_70" data-name="Rectangle 70" transform="translate(21.856 13.856) rotate(150)" fill="#fff" stroke="#00aeea" stroke-width="2">
        <rect width="16" height="16" stroke="none"/>
        <rect x="1" y="1" width="14" height="14" fill="none"/>
      </g>
    </svg>
    
      `
  },
]



class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.setLang = this.setLang.bind(this);
    this.setLightMode = this.setLightMode.bind(this);


    let lang = 'ba';
    if (typeof window !== 'undefined') {

      window.googleMapsCallback = this.googleMapsCallback;

      if (props.location.pathname.indexOf('/en') !== -1) {
        lang = 'en';
      }


    } else {
      lang = this.props.lang;
    }
    this.state = {
      _googleMapsLoaded: false,
      lang: lang,
      lightMode: 0,
      services: [],
      latestNews: [],
      ...props.appInitialData

    };

  }

  setLang(lang) {
    this.setState({
      lang: lang
    });
  }
  setLightMode(val) {
    this.setState({
      lightMode: val
    });
  }





  translate(text) {
    return text;
  }

  render() {
    let meta;

    if (this.state.metaTags) {
      meta = {
        title: this.state.metaTags.title && this.state.metaTags.title[this.state.lang],
        description: this.state.metaTags.description && this.state.metaTags.description[this.state.lang],
        //canonical: 'http://example.com/path/to/page',
        meta: {
          charset: 'utf-8',
          name: {
            'og:url': this.state.metaTags['og:url'] && this.state.metaTags['og:url'][this.state.lang],
            'og:title': this.state.metaTags['og:title'] && this.state.metaTags['og:title'][this.state.lang],
            'og:image': this.state.metaTags['og:image'] && this.state.metaTags['og:image'][this.state.lang],
            'og:description': this.state.metaTags['og:description'] && this.state.metaTags['og:description'][this.state.lang],
            'og:type': this.state.metaTags['og:type'] && this.state.metaTags['og:type'][this.state.lang],
          }
        }
      };
    }

    return (
      <Provider store={store}>
        {this.state.metaTags ? <DocumentMeta {...meta} /> : null}
        <Routes
          {...this.state}
          translate={this.translate}
          setLang={this.setLang}
          setLightMode={this.setLightMode}
          serverFetch={this.props.serverFetch}
          initialData={this.props.initialData ? this.props.initialData : {}}
          allowCookies={() => {
            localStorage.allowCookies = true;
            this.setState({
              cookies: true
            });
          }}
        />
      </Provider>

    );

  }





  componentDidMount() {
    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }




    let body = window.document.body;


    if (window.innerWidth >= 1600 && document.getElementsByClassName('svg-icons').length == 0) {
      for (let i = 0; i < svgs.length; i++) {
        if (svgs[i].y < window.document.body.scrollHeight) {
          let element = document.createElement('div');
          element.setAttribute('class', 'svg-icons');
          element.style.position = 'absolute';
          element.style.top = `${svgs[i].y}px`;
          if (svgs[i].right) {
            element.style.right = `${svgs[i].x}px`;

          } else {
            element.style.left = `${svgs[i].x}px`;
          }
          element.innerHTML = svgs[i].svg;

          body.appendChild(element);
        }
      }
    }





    window.addEventListener('resize', function () {

      let navigationContainer = document.getElementById('navigation-container');

      if (window.innerWidth < 1720) {
        navigationContainer.style.maxWidth = (1140 - (1720 - window.innerWidth)) + 'px';
      } else {

      }

      var elements = document.getElementsByClassName('svg-icons');
      for (let i = 0; i < elements.length; i++) {
        if (window.innerWidth >= 1600) {
          elements[i].style.display = 'block';
        } else {
          elements[i].style.display = 'none';

        }
      }
    });


    var elements = document.getElementsByClassName('svg-icons');
    for (let i = 0; i < elements.length; i++) {
      if (window.innerWidth >= 1600) {
        elements[i].style.display = 'block';
      } else {
        elements[i].style.display = 'none';

      }
    }



    //ReactGA.initialize('UA-75168242-1');

    this.props.history.listen((location, action) => {
      let lang = 'ba';
      if (location.pathname.indexOf('/en') !== -1) {
        lang = 'en';
      } else if (location.pathname.indexOf('/sr') !== -1) {
        lang = 'sr';
      }

      if (this.state.lang != lang) {
        this.setState({ lang: lang }, () => {
          fetch('https://toptls-api.novamedia.agency/seo/' + this.state.lang, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: location.pathname })
          }).then((res) => res.json()).then((result) => {

            this.setState({
              metaTags: result
            })
          });

        });
      } else {
        fetch('https://toptls-api.novamedia.agency/seo/' + this.state.lang, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ url: location.pathname })
        }).then((res) => res.json()).then((result) => {

          this.setState({
            metaTags: result
          })
        });

      }


      //ReactGA.pageview(location.pathname + location.search);

      // location is an object like window.location

      //console.log(action, location.pathname, location.state)





    });




    fetch('https://api.novamedia.agency/services', {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    }).then(res => res.json()).then((services) => {
      console.log(services);
      this.setState({
        services
      })
    })

    fetch('https://api.novamedia.agency/blog/latest', {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    }).then(res => res.json()).then((latestNews) => {
      console.log(latestNews);
      this.setState({
        latestNews
      })
    })


    








  }



  googleMapsCallback() {
    console.log("true");
    this.setState({ _googleMapsLoaded: true });
  }

}

export default withRouter(App);
