import React, { Component } from 'react';
import Link from './link';
import Isvg from 'react-inlinesvg';

/*header*/

import logo from '../assets/svg/logo.svg';
import rightChevron from '../assets/svg/right-chevron.svg';
import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';
import linkedin from '../assets/svg/linkedin.svg';
import twitter from '../assets/svg/twitter.svg';

import Map from '../components/map';

import {
    Container,
    Row,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }




    render() {

        return (
            <footer class-name="el_zTawZSm4DV" className="el_zTawZSm4DV">
            <div class-name="el_REPWjUnHGM" className="el_REPWjUnHGM container">
                <div class-name="el_jcIsZaOJHo" className="el_jcIsZaOJHo row">
                    <div class-name="el_qTfy8h25u8" className="el_qTfy8h25u8 col-lg-4">

                        <div class-name="el_cAkJ4t5OaQ" className="el_cAkJ4t5OaQ">
                            <div id="google-map_fgqeT" className="google-map el_Xw7lQm2EOm"
                                class-name="el_Xw7lQm2EOm">
                                <Map {...this.props}/>
                                </div>
                        </div>
                    </div>
                    <div class-name="el_wW07IzKFrB" className="el_wW07IzKFrB col-lg-6 offset-lg-2">
                        <div class-name="el_elum5o2Hot" className="el_elum5o2Hot">
                            <div class-name="el_ZhzZiF9PQS" className="el_ZhzZiF9PQS"><svg xmlns="http://www.w3.org/2000/svg"
                                width="31.625" height="46" viewBox="0 0 31.625 46">
                                <g id="iconfinder_phone_115698" transform="translate(-5)">
                                    <path id="Path_11" data-name="Path 11" d="M32.313,0h-23A4.311,4.311,0,0,0,5,4.313V41.688A4.311,4.311,0,0,0,9.313,46h23a4.314,4.314,0,0,0,4.313-4.312V4.313A4.312,4.312,0,0,0,32.313,0ZM33.75,41.688a1.44,1.44,0,0,1-1.437,1.438h-23a1.437,1.437,0,0,1-1.437-1.437V38.807H33.75Zm0-4.318H7.875V8.625H33.75Zm0-30.182H7.875V4.313A1.438,1.438,0,0,1,9.313,2.875h23A1.439,1.439,0,0,1,33.75,4.313Z"
                                        fill="#00aeea"></path>
                                    <path id="Path_12" data-name="Path 12" d="M19.907,3.738a.74.74,0,0,1-.738.738H14.738A.738.738,0,0,1,14,3.738h0A.737.737,0,0,1,14.738,3h4.431a.739.739,0,0,1,.738.738Z"
                                        transform="translate(3.859 1.431)" fill="#00aeea"></path>
                                    <path id="Path_13" data-name="Path 13" d="M17.954,28.734a.741.741,0,0,1-.738.738H15.738A.74.74,0,0,1,15,28.734h0A.738.738,0,0,1,15.738,28h1.477a.739.739,0,0,1,.738.738Z"
                                        transform="translate(4.336 12.091)" fill="#00aeea"></path>
                                </g>
                            </svg>

                            </div>
                            <div class-name="el_gcIkRF1HsV" className="el_gcIkRF1HsV">
                                <p class-name="el_Zuoxdu4dzL" className="el_Zuoxdu4dzL">{'Kontakt:'.translate(this.props.lang)}</p>
                                <p class-name="el_SManLciQ3o" className="el_SManLciQ3o">+387 (0) 33 452 252<br />+387 (0) 62 730
                        911<br/>kontakt@toptls.ba </p>
                            </div>
                        </div>
                        <div class-name="el_elum5o2Hot" className="el_elum5o2Hot">
                            <div class-name="el_ZhzZiF9PQS" className="el_ZhzZiF9PQS"><svg id="iconfinder_miscellaneous-23_809465"
                                xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46"
                                class-name="el_84OGGpKQvR" className="el_84OGGpKQvR">
                                <path id="Path_35" data-name="Path 35" d="M23,0A23,23,0,1,0,46,23,23.068,23.068,0,0,0,23,0Zm0,42.167A19.167,19.167,0,1,1,42.167,23,19.223,19.223,0,0,1,23,42.167Z"
                                    fill="#00aeea"></path>
                                <path id="Path_36" data-name="Path 36" d="M17.577,21.456c0-.389.194-.583.194-.971a3.76,3.76,0,0,0-1.943-3.3V4.943A1.835,1.835,0,0,0,13.885,3a1.835,1.835,0,0,0-1.943,1.943V17.182A3.76,3.76,0,0,0,10,20.484a3.9,3.9,0,0,0,3.885,3.885,1.751,1.751,0,0,0,.971-.194l3.5,3.5a1.923,1.923,0,0,0,2.72-2.72Zm-3.691.971a1.835,1.835,0,0,1-1.943-1.943,1.835,1.835,0,0,1,1.943-1.943,1.835,1.835,0,0,1,1.943,1.943A1.835,1.835,0,0,1,13.885,22.427Z"
                                    transform="translate(9.08 2.828)" fill="#00aeea"></path>
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.943" cy="1.943" r="1.943" transform="translate(36.286 21.057)"
                                    fill="#00aeea"></circle>
                                <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.943" cy="1.943" r="1.943" transform="translate(5.828 21.057)"
                                    fill="#00aeea"></circle>
                                <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.943" cy="1.943" r="1.943" transform="translate(31.969 31.969)"
                                    fill="#00aeea" class-name="el_SJS3qzKrEJ" className="el_SJS3qzKrEJ"></circle>
                                <circle id="Ellipse_5" data-name="Ellipse 5" cx="1.943" cy="1.943" r="1.943" transform="translate(10.146 10.146)"
                                    fill="#00aeea"></circle>
                                <circle id="Ellipse_6" data-name="Ellipse 6" cx="1.943" cy="1.943" r="1.943" transform="translate(21.057 36.286)"
                                    fill="#00aeea"></circle>
                                <circle id="Ellipse_7" data-name="Ellipse 7" cx="1.943" cy="1.943" r="1.943" transform="translate(10.146 31.969)"
                                    fill="#00aeea"></circle>
                                <circle id="Ellipse_8" data-name="Ellipse 8" cx="1.943" cy="1.943" r="1.943" transform="translate(31.969 10.146)"
                                    fill="#00aeea"></circle>
                            </svg>

                            </div>
                            <div class-name="el_gcIkRF1HsV" className="el_gcIkRF1HsV">
                                <p class-name="el_Zuoxdu4dzL" className="el_Zuoxdu4dzL">{'Radno vrijeme:'.translate(this.props.lang)}</p>
                                <p class-name="el_SManLciQ3o" className="el_SManLciQ3o">PON - PET<br />07:30 - 20:00 h</p>
                                <p class-name="el_SManLciQ3o" className="el_SManLciQ3o">SUB<br />07:30 - 16:00 h</p>
                            </div>
                        </div>
                    </div>
                    <div class-name="el_EASeU6SNPk" className="el_EASeU6SNPk col-12">
                        <p class-name="el_84wPPkcbcM" className="el_84wPPkcbcM">Copyright @ TopTLS d.o.o.- 2020. All Right Reserved</p>
                        <p class-name="el_BL44VksN8g" className="el_BL44VksN8g">Created by nova media.</p>
                    </div>
                </div>
            </div>
        </footer>
        );
    }
}

export default Footer;