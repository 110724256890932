import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';



import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';



import downChevron from '../assets/svg/down-chevron.svg';

import Map from '../components/map';
import ContactForm from '../components/forms/contactForm';

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {

        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

    }

    submit(data) {
        this.setState({
            formLoading: true
        }, () => {

            fetch('https://toptls-api.novamedia.agency/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(
                    data
                )
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    //formLoading: null,
                    formDone: true
                })
            })
        })



        console.log(data);
    }


    render() {

        return (
            <div>
                <section class-name="el_MWt_UkcEbY" className="el_MWt_UkcEbY">
                    <div class-name="el_nxSTNAI73a" className="el_nxSTNAI73a container">
                        <div class-name="el_xXSSMBLR64" className="el_xXSSMBLR64 row">
                            <div class-name="el_CEQDddamsC" className="el_CEQDddamsC col-lg-12">
                                <h1 class-name="el_36aob6dvPA" className="el_36aob6dvPA">{'Kontakt'.translate(this.props.lang)}</h1>
                                <ul class-name="el_P3NocKyAEA" className="el_P3NocKyAEA">
                                    <li class-name="el_Jl1PjCRvxx" className="el_Jl1PjCRvxx">{'Početna'.translate(this.props.lang)}</li>
                                    <li class-name="el_Jl1PjCRvxx" className="el_Jl1PjCRvxx">{'Kontakt'.translate(this.props.lang)}</li>
                                </ul>
                            </div>
                            <div class-name="el_g6zpWXv0UX" className="el_g6zpWXv0UX col-lg-8">
                                <p class-name="el_YUkXJVvxfk" className="el_YUkXJVvxfk">{'Budite slobodni da nas kontaktirate. Odgovorit ćemo vam u što kraćem roku.'.translate(this.props.lang)}</p>
                                <ContactForm lang={this.props.lang} onSubmit={this.submit} loading={this.state.formLoading}></ContactForm>
                                {this.state.formDone ?
                                        <p className="el_YUkXJVvxfk">{'Vaša poruka je poslata, očekujte odgovor ubrzo.'.translate(this.props.lang)}</p>
                                    :
                                    null
                                }
                            </div>
                            <div class-name="el_Hk4knNUipS" className="el_Hk4knNUipS col-lg-4">
                                <div class-name="el_FeM8_W4aS4" className="el_FeM8_W4aS4">
                                    <h6 class-name="el_w6VHaa50lc" className="el_w6VHaa50lc">{'INFORMACIJE'.translate(this.props.lang)}</h6>
                                    <p class-name="el_ydjzzEz23s" className="el_ydjzzEz23s"><span class-name="el_Zhp86yOBop" className="el_Zhp86yOBop">TOP TLS d.o.o.</span><br />Braće Baljić 1c, Sarajevo 71000<br />Bosna i Hercegovina</p>
                                    <ul class-name="el_kNKib5LNP_" className="el_kNKib5LNP_">
                                        <li class-name="el_dEMoNOhJEQ" className="el_dEMoNOhJEQ">
                                            <div class-name="el_Bw52B_0EZf" className="el_Bw52B_0EZf"><svg xmlns="http://www.w3.org/2000/svg" width="25.506" height="25.624" viewBox="0 0 25.506 25.624" class-name="el_YQMMOqFCx0" className="el_YQMMOqFCx0">
                                                <g id="iconfinder_phone1_172517" transform="translate(0.506 0.624)">
                                                    <rect id="Rectangle_385" data-name="Rectangle 385" width="23.757" height="23.757" fill="none"></rect>
                                                    <path id="Path_2820" data-name="Path 2820" d="M15.307,17.773s1.983-1.135,2.5-1.408a1.488,1.488,0,0,1,1.379-.144c.493.3,4.634,3.056,4.983,3.3a1.18,1.18,0,0,1,.037,1.626c-.477.684-2.68,3.387-3.613,3.358s-4.822-.116-12.151-7.447S1.03,5.842,1,4.907,3.673,1.769,4.357,1.291a1.2,1.2,0,0,1,1.626.038c.276.382,3,4.51,3.3,4.983a1.472,1.472,0,0,1-.144,1.38c-.272.519-1.407,2.5-1.407,2.5a24.316,24.316,0,0,0,3.506,4.07A24.431,24.431,0,0,0,15.307,17.773Z" transform="translate(-0.505 -0.505)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"></path>
                                                </g>
                                            </svg></div>
                                            <p class-name="el_0DwSg2Bm7Z" className="el_0DwSg2Bm7Z">+387 (0) 33 452 252</p>
                                        </li>
                                        <li class-name="el_dEMoNOhJEQ" className="el_dEMoNOhJEQ">
                                            <div class-name="el_Bw52B_0EZf" className="el_Bw52B_0EZf"><svg xmlns="http://www.w3.org/2000/svg" width="17.875" height="26" viewBox="0 0 17.875 26">
                                                <g id="iconfinder_phone_115698" transform="translate(-5)">
                                                    <path id="Path_11" data-name="Path 11" d="M20.438,0h-13A2.437,2.437,0,0,0,5,2.438V23.563A2.437,2.437,0,0,0,7.437,26h13a2.438,2.438,0,0,0,2.437-2.437V2.438A2.437,2.437,0,0,0,20.438,0Zm.812,23.563a.814.814,0,0,1-.812.813h-13a.812.812,0,0,1-.812-.812V21.934H21.25Zm0-2.441H6.625V4.875H21.25Zm0-17.059H6.625V2.438a.813.813,0,0,1,.813-.812h13a.813.813,0,0,1,.812.813Z" transform="translate(0)" fill="#fff"></path>
                                                    <path id="Path_12" data-name="Path 12" d="M17.312,3.414a.415.415,0,0,1-.414.414H14.414A.414.414,0,0,1,14,3.414h0A.413.413,0,0,1,14.414,3H16.9a.414.414,0,0,1,.414.414Z" transform="translate(-1.719 -0.516)" fill="#fff"></path>
                                                    <path id="Path_13" data-name="Path 13" d="M16.656,28.41a.415.415,0,0,1-.414.414h-.828A.415.415,0,0,1,15,28.41h0A.414.414,0,0,1,15.414,28h.828a.414.414,0,0,1,.414.414Z" transform="translate(-1.891 -5.312)" fill="#fff"></path>
                                                </g>
                                            </svg></div>
                                            <p class-name="el_0DwSg2Bm7Z" className="el_0DwSg2Bm7Z">+387 (0) 62 730 911</p>
                                        </li>
                                        <li class-name="el_dEMoNOhJEQ" className="el_dEMoNOhJEQ">
                                            <div class-name="el_Bw52B_0EZf" className="el_Bw52B_0EZf"><svg xmlns="http://www.w3.org/2000/svg" width="26.355" height="21.414" viewBox="0 0 26.355 21.414">
                                                <g id="iconfinder_Appliances-38_976619" transform="translate(0 -3)">
                                                    <path id="Path_2829" data-name="Path 2829" d="M25.12,9.589h-7V3H3.294V9.589H1.235A1.235,1.235,0,0,0,0,10.824V23.178a1.235,1.235,0,0,0,1.235,1.235H25.12a1.235,1.235,0,0,0,1.235-1.235V10.824A1.235,1.235,0,0,0,25.12,9.589ZM2.471,18.648H18.943V23.59H2.471Zm17.3-8.236h4.118V23.59H19.766ZM4.118,3.824H17.3V9.589H4.118Zm14,6.589h.824v7.412H2.471V10.412ZM.824,23.178V10.824a.412.412,0,0,1,.412-.412h.412V23.59H1.235A.412.412,0,0,1,.824,23.178Zm24.708,0a.412.412,0,0,1-.412.412h-.412V10.412h.412a.412.412,0,0,1,.412.412Z" fill="#fff"></path>
                                                    <rect id="Rectangle_386" data-name="Rectangle 386" width="6" transform="translate(5.355 5)" fill="#fff"></rect>
                                                    <rect id="Rectangle_387" data-name="Rectangle 387" width="10" height="1" transform="translate(5.355 6)" fill="#fff"></rect>
                                                    <rect id="Rectangle_388" data-name="Rectangle 388" width="5" height="1" transform="translate(5.355 8)" fill="#fff"></rect>
                                                    <rect id="Rectangle_389" data-name="Rectangle 389" width="3" height="1" transform="translate(20.355 21)" fill="#fff"></rect>
                                                    <rect id="Rectangle_390" data-name="Rectangle 390" width="3" height="1" transform="translate(20.355 19)" fill="#fff"></rect>
                                                    <rect id="Rectangle_391" data-name="Rectangle 391" width="3" height="1" transform="translate(20.355 18)" fill="#fff"></rect>
                                                    <circle id="Ellipse_9" data-name="Ellipse 9" cx="0.824" cy="0.824" r="0.824" transform="translate(21.002 12.06)" fill="#fff"></circle>
                                                </g>
                                            </svg></div>
                                            <p class-name="el_0DwSg2Bm7Z" className="el_0DwSg2Bm7Z">+387 (0) 33 452 252</p>
                                        </li>
                                    </ul>
                                    <ul class-name="el_kNKib5LNP_" className="el_kNKib5LNP_">
                                        <li class-name="el_dEMoNOhJEQ" className="el_dEMoNOhJEQ">
                                            <div class-name="el_Bw52B_0EZf" className="el_Bw52B_0EZf"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.6" viewBox="0 0 24 15.6">
                                                <g id="Outline" transform="translate(-2 -11)">
                                                    <g id="Group_85" data-name="Group 85" transform="translate(2 11)">
                                                        <path id="Path_113" data-name="Path 113" d="M23.6,11H4.4A2.4,2.4,0,0,0,2,13.4V24.2a2.4,2.4,0,0,0,2.4,2.4H23.6A2.4,2.4,0,0,0,26,24.2V13.4A2.4,2.4,0,0,0,23.6,11Zm-2.264,2.4L14,17.985,6.664,13.4ZM4.4,24.2V14.815l8.964,5.6a1.2,1.2,0,0,0,1.272,0l8.964-5.6V24.2Z" transform="translate(-2 -11)" fill="#fff"></path>
                                                    </g>
                                                </g>
                                            </svg></div>
                                            <p class-name="el_0DwSg2Bm7Z" className="el_0DwSg2Bm7Z">kontakt@toptls.ba</p>
                                        </li>
                                    </ul>
                                    <p class-name="el_nq1OyYokqg" className="el_nq1OyYokqg">{'Top TLS d.o.o. korisni podaci'.translate(this.props.lang)}</p>
                                    <p class-name="el_JZnj37jxuM" className="el_JZnj37jxuM">
                                        {'ID broj'.translate(this.props.lang)}: 4202109820004
                            <br />
                                        {'PDV broj:'.translate(this.props.lang)} 202109820004
                            <br />
                                        {'Registracija:'.translate(this.props.lang)} 065-0-Reg-15-002547
                            <br />
                                        {'Općinski sud u Sarajevu'.translate(this.props.lang)}
                            <br /><br />
                                        {'Transakcijski račun – UniCredit bank'.translate(this.props.lang)}
                            <br />
                                        3387202277709577
                            <br /><br />
                                        {'Devizni račun (IBAN) – UniCredit bank'.translate(this.props.lang)}
                            <br />
                                        BA393387204877710769
                            <br /><br />
                                        {'Devizni račun (SWIFT):'.translate(this.props.lang)} UNCRBA 22
                            </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Page(ContactPage);