import HomePage from './views/homePage';
import AboutUsPage from './views/aboutUsPage';
import ContactPage from './views/contactPage';
import ServicePage from './views/servicePage';
import BlogPage from './views/blogPage';
import BlogDetailPage from './views/blogDetailPage';
import SpecificationPage from './views/specificationPage';

const linkTranslates = {
    
    'en': {
        '/' : '/en',
        '/usluge': '/en/services',
        '/usluge/:alias': '/en/services/:alias',
        '/usluge/:parentAlias/:alias': '/en/services/:parentAlias/:alias',
        '/o-nama': '/en/about-us',
        '/novosti': '/en/blog',
        '/novosti/:alias': '/en/blog/:alias',
        '/kontakt': '/en/contact',
    },
}

export const routes = [
    {
        path: "/",
        component: HomePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://toptls-api.novamedia.agency/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://toptls-api.novamedia.agency/services', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                    
                }).then(res => res.json()).then((result) => {

                    return {
                        services: result,
                    }
                })

            },

            (fetchFunction, lang, match) => {
                return fetchFunction('https://toptls-api.novamedia.agency/pages', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                   
                }).then(res => res.json()).then((result) => {

                    return {
                        pages: result,
                    }
                })

            },
            
            (fetchFunction, lang, match) => {
                return fetchFunction('https://toptls-api.novamedia.agency/blog/latest', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                    
                }).then(res => res.json()).then((result) => {

                    return {
                        latestBlog: result,
                    }
                })

            }

        ]

    },
    {
        path: "/o-nama",
        component: AboutUsPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://toptls-api.novamedia.agency/aboutus', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://toptls-api.novamedia.agency/blog/latest', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                    
                }).then(res => res.json()).then((result) => {

                    return {
                        latestBlog: result,
                    }
                })

            }



        ]

    },
    {
        path: "/novosti",
        component: BlogPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://toptls-api.novamedia.agency/blog', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                    
                }).then(res => res.json()).then((result) => {

                    return {
                        items: result,
                    }
                })

            }



        ]

    },
    {
        path: "/novosti/:alias",
        component: BlogDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://toptls-api.novamedia.agency/blog/latest', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                    
                }).then(res => res.json()).then((result) => {

                    return {
                        latestBlog: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://toptls-api.novamedia.agency/blog/detail/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                    
                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            }




        ]

    },


    {
        path: "/usluge/:alias",
        component: ServicePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://toptls-api.novamedia.agency/pages/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                   
                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            },

            (fetchFunction, lang, match) => {
                return fetchFunction('https://toptls-api.novamedia.agency/pages', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                   
                }).then(res => res.json()).then((result) => {

                    return {
                        pages: result,
                    }
                })

            }

        ]
    },
    {
        path: "/usluge/:parentAlias/:alias",
        component: ServicePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://toptls-api.novamedia.agency/pages/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                   
                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            },

            (fetchFunction, lang, match) => {
                return fetchFunction('https://toptls-api.novamedia.agency/pages', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                   
                }).then(res => res.json()).then((result) => {

                    return {
                        pages: result,
                    }
                })

            }

        ]
    },
    {
        path: "/kontakt",
        component: ContactPage,
        loadData: []
    },
    {
        path: "/uvoz-vozila",
        component: SpecificationPage,
        loadData: []
    },
];

export function generateRoutes(r) {
    let newRoutes = [];
    for(let i=0;i<r.length;i++){
        newRoutes.push(r[i]);

        newRoutes.push( {
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })
    }
    return newRoutes;
}