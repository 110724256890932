import { matchPath } from 'react-router-dom';


const allLinks = [

    '/',
    '/usluge',
    '/usluge/:alias',
    '/usluge/:parentAlias/:alias',
    '/o-nama',
    '/novosti',
    '/novosti/:alias',
    '/uvoz-vozila',
    '/kontakt',
]

const linkTranslates = {
  
    'en': {
        '/': '/en',
        '/usluge': '/en/services',
        '/usluge/:alias': '/en/services/:alias',
        '/usluge/:parentAlias/:alias': '/en/services/:parentAlias/:alias',
        '/o-nama': '/en/about-us',
        '/novosti': '/en/blog',
        '/uvoz-vozila': '/en/import-vehicles”',
        '/novosti/:alias': '/en/blog/:alias',
        '/kontakt': '/en/contact',
    },
}

export default function (link, lang){
    let to = link;
    if (!lang){
        lang = 'ba';
    }

    if (lang !== 'ba') {
        allLinks.some(route => {

            const match = matchPath(to, route);
            if (match && match.isExact) {
                to = to.replace(route.split(':')[0], linkTranslates[lang][route].split(':')[0])
            }
            return match && match.isExact;
        });
    }


    return to;
}