import React, { Component } from 'react';
import Link from './link';
import Isvg from 'react-inlinesvg';

/*header*/

import logo from '../assets/svg/logo.svg';
import rightChevron from '../assets/svg/right-chevron.svg';
import upArrow from '../assets/svg/up.svg';
import mode from '../assets/svg/mode.svg';

import translateLinkToOrig from '../translateLinkToOrig';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';


class Header extends Component {
    constructor(props) {
        super(props);
        this.generateLangLink = this.generateLangLink.bind(this);

        this.state = {
            yScroll: 0
        };
    }

    generateLangLink(lang) {

        return `/?lang=${lang}`;

    }

    componentDidUpdate(prevProps) {
        if (prevProps.lightMode != this.props.lightMode) {
            setTimeout(() => {
                this.setState({
                    showMobileLight: null
                })
            }, 800)
        }
    }


    componentDidMount() {
        if (typeof window !== 'undefined')
            window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined')
            window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const yScroll =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll: yScroll,
            scrollHeader: yScroll < this.state.yScroll,
        })
    }



    render() {
        //alert(this.props.lang)
        return (
            <header class-name="el_BUGVv7iQ9s" className="el_BUGVv7iQ9s">
                <div class-name="el_mw3nphhmMH" className="el_mw3nphhmMH">
                    <Link lang={this.props.lang} to={'/'}><Isvg src={logo} /></Link>

                </div>
                <div class-name="el_ffOne8QdfY" className="el_ffOne8QdfY container" id="navigation-container">
                    <ul class-name="el_0LIr5zSL8I" className="el_0LIr5zSL8I">
                        <li className="el_GwD4F2UbvD">
                            <Link lang={this.props.lang} className={this.props[0].location.pathname == '/' ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"} to="/">
                                {'Početna'.translate(this.props.lang)}
                            </Link>
                        </li>
                        <li className="el_GwD4F2UbvD">
                            <Link lang={this.props.lang} className={this.props[0].location.pathname == '/o-nama' ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"} to="/o-nama">
                                {'O nama'.translate(this.props.lang)}
                            </Link>
                        </li>
                        <li className="el_GwD4F2UbvD">
                            <Link lang={this.props.lang} className={this.props[0].location.pathname.indexOf('/usluge') == 0 ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"} to={this.props.lang == 'en' ? "/en/services/international-transport-of-goods" : "/usluge/medjunarodni-transport-roba"}>
                                {'Usluge'.translate(this.props.lang)}
                            </Link>
                        </li>
                        <li className="el_GwD4F2UbvD">
                            <Link lang={this.props.lang} className={this.props[0].location.pathname == '/uvoz-vozila' ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"} to="/uvoz-vozila">
                                {'Uvoz vozila'.translate(this.props.lang)}
                            </Link>
                        </li>
                        <li className="el_GwD4F2UbvD">
                            <Link lang={this.props.lang} className={this.props[0].location.pathname.indexOf('/novosti') == 0 ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"} to="/novosti">
                                {'Novosti'.translate(this.props.lang)}
                            </Link>
                        </li>
                        <li className="el_GwD4F2UbvD">
                            <Link lang={this.props.lang} className={this.props[0].location.pathname == '/kontakt' ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"} to="/kontakt">
                                {'Kontakt'.translate(this.props.lang)}
                            </Link>
                        </li>
                        <li>
                            <Link lang={this.props.lang} className={this.props[0].location.pathname == '/vas-upit' ? "nav-active" : ""} to={this.props.lang == 'en' ? "/en/services/your-inquiry" : "/usluge/vas-upit"}>
                                <button class-name="el_Jn2MR5JahL" className="el_Jn2MR5JahL">
                                    {'Vaš upit'.translate(this.props.lang)}
                                </button>
                            </Link>
                        </li>
                        <li className="lang-dropdown">
                            <UncontrolledDropdown>
                                <DropdownToggle>
                                    {this.props.lang}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem><Link  to='/'>BA</Link></DropdownItem>
                                    <DropdownItem><Link  to='/en'>EN</Link></DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </li>

                    </ul>
                </div>
                <div class-name="el_u16OueG4Nf" className="el_u16OueG4Nf">
                    <a href="https://www.facebook.com/toptls" target="_blank" class-name="el_KzmiGGrUC3" className="el_KzmiGGrUC3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" class-name="el_dF9V1LJ7aD"
                            className="el_dF9V1LJ7aD"
                        >
                            <path id="iconfinder_facebook_circle_black_107153" d="M18.03,31.02h3.934V21.5h2.624l.348-3.281H21.964l0-1.643c0-.855.082-1.314,1.309-1.314h1.64V11.98H22.292c-3.153,0-4.262,1.592-4.262,4.268v1.97H16.064V21.5H18.03ZM20.5,39A17.5,17.5,0,1,1,38,21.5,17.5,17.5,0,0,1,20.5,39Z"
                                transform="translate(-3 -4)" fill="#1f1a16" fill-rule="evenodd" class-name="el_DQ6T9tQ8rN"
                                className="el_DQ6T9tQ8rN">
                            </path>
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/toptls/" target="_blank" class-name="el_nowwftO3rZ" className="el_nowwftO3rZ"><svg id="iconfinder_38-instagram_1161954" xmlns="http://www.w3.org/2000/svg"
                    width="35" height="35" viewBox="0 0 35 35">
                    <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="3.135" cy="3.135" rx="3.135" ry="3.135" transform="translate(14.222 14.507)"
                        fill="#1f1a16"></ellipse>
                    <path id="Path_9" data-name="Path 9" d="M28.915,19.2H21.077a3.894,3.894,0,0,0-2.779,1,3.894,3.894,0,0,0-1,2.779v7.838a3.928,3.928,0,0,0,1.069,2.85,4.017,4.017,0,0,0,2.779,1h7.767a3.894,3.894,0,0,0,2.779-1,3.65,3.65,0,0,0,1.069-2.779V23.048a4.017,4.017,0,0,0-1-2.779A3.815,3.815,0,0,0,28.915,19.2ZM25,31.742a4.846,4.846,0,0,1,0-9.691,4.846,4.846,0,1,1,0,9.691Zm5.059-8.765a1.14,1.14,0,1,1,1.14-1.14A1.126,1.126,0,0,1,30.055,22.977Z"
                        transform="translate(-7.638 -9.254)" fill="#1f1a16"></path>
                    <path id="Path_10" data-name="Path 10" d="M21.2,5.2A17.5,17.5,0,1,0,38.7,22.7,17.487,17.487,0,0,0,21.2,5.2Zm9.319,21.626a5.639,5.639,0,0,1-1.565,4.055,5.536,5.536,0,0,1-3.984,1.494H17.216a5.536,5.536,0,0,1-3.984-1.494,5.36,5.36,0,0,1-1.565-4.055V19a5.265,5.265,0,0,1,5.549-5.549h7.825A5.246,5.246,0,0,1,30.519,19v7.825Z"
                        transform="translate(-3.7 -5.2)" fill="#1f1a16"></path>
                </svg>

                    </a></div>
                <button class-name="el_fYHnq_KeMK" className="el_fYHnq_KeMK" id="mobile-hamburger" onClick={() => this.setState({ mobileMenu: true })}>
                    <div class-name="el_GAg23_3A7k" className="el_GAg23_3A7k"><svg xmlns="http://www.w3.org/2000/svg" width="32"
                        height="24" viewBox="0 0 32 24">
                        <g id="Group_336" data-name="Group 336" transform="translate(-1660 -702)">
                            <rect id="Rectangle_409" data-name="Rectangle 409" width="32" height="4" rx="2" transform="translate(1660 702)"
                                fill="#444"></rect>
                            <rect id="Rectangle_410" data-name="Rectangle 410" width="32" height="4" rx="2" transform="translate(1660 712)"
                                fill="#444"></rect>
                            <rect id="Rectangle_411" data-name="Rectangle 411" width="20" height="4" rx="2" transform="translate(1672 722)"
                                fill="#444"></rect>
                        </g>
                    </svg>

                    </div>
                </button>
                <div class-name="el_1JJW5wljzB" className="el_1JJW5wljzB" id="mobile-menu" style={{ left: this.state.mobileMenu ? '0px' : '110%' }}>
                    <ul class-name="el_0LIr5zSL8I" className="el_0LIr5zSL8I">
                        <li className="el_GwD4F2UbvD"><Link lang={this.props.lang} className={this.props[0].location.pathname == '/' ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"}
                            to="/">{'Početna'.translate(this.props.lang)}</Link></li>
                        <li className="el_GwD4F2UbvD"><Link lang={this.props.lang} className={this.props[0].location.pathname == '/o-nama' ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"}
                            to="/o-nama">{'O nama'.translate(this.props.lang)}</Link></li>
                        <li className="el_GwD4F2UbvD"><Link lang={this.props.lang} className={this.props[0].location.pathname.indexOf('/usluge') == 0 ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"}
                            to={this.props.lang == 'en' ? "/en/services/international-transport-of-goods" : "/usluge/medjunarodni-transport-roba"}>{'Usluge'.translate(this.props.lang)}</Link></li>
                        <li className="el_GwD4F2UbvD"><Link lang={this.props.lang} className={this.props[0].location.pathname == '/uvoz-vozila' ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"}
                            to="/uvoz-vozila">{'Uvoz vozila'.translate(this.props.lang)}</Link></li>
                        <li className="el_GwD4F2UbvD"><Link lang={this.props.lang} className={this.props[0].location.pathname.indexOf('/novosti') == 0 ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"}
                            to="/novosti">{'Novosti'.translate(this.props.lang)}</Link></li>
                        <li className="el_GwD4F2UbvD"><Link lang={this.props.lang} className={this.props[0].location.pathname == '/kontakt' ? "el_ilIu4Q1z6O nav-active" : "el_ilIu4Q1z6O"}
                            to="/kontakt">{'Kontakt'.translate(this.props.lang)}</Link></li>
                        <li  ><Link lang={this.props.lang} className={this.props[0].location.pathname == '/vas-upit' ? "nav-active" : ""}
                            to={this.props.lang == 'en' ? "/en/services/your-inquiry" : "/usluge/vas-upit"} ><button class-name="el_Jn2MR5JahL" className="el_Jn2MR5JahL">{'Vaš upit'.translate(this.props.lang)}</button></Link></li>
                                                    <li className="lang-dropdown">
                            <UncontrolledDropdown>
                                <DropdownToggle>
                                    {this.props.lang}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem><Link  to='/'>BA</Link></DropdownItem>
                                    <DropdownItem><Link  to='/en'>EN</Link></DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </li>

                        <span className="mobile-social">
                        <a href="https://www.facebook.com/toptls" target="_blank" class-name="el_KzmiGGrUC3" className="el_KzmiGGrUC3 mobile-facebook">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" class-name="el_dF9V1LJ7aD"
                                className="el_dF9V1LJ7aD"
                            >
                                <path id="iconfinder_facebook_circle_black_107153" d="M18.03,31.02h3.934V21.5h2.624l.348-3.281H21.964l0-1.643c0-.855.082-1.314,1.309-1.314h1.64V11.98H22.292c-3.153,0-4.262,1.592-4.262,4.268v1.97H16.064V21.5H18.03ZM20.5,39A17.5,17.5,0,1,1,38,21.5,17.5,17.5,0,0,1,20.5,39Z"
                                    transform="translate(-3 -4)" fill="#1f1a16" fill-rule="evenodd" class-name="el_DQ6T9tQ8rN"
                                    className="el_DQ6T9tQ8rN">
                                </path>
                            </svg>
                        </a>
                        </span>
                        <span className="mobile-social">
                            <a href="https://www.instagram.com/toptls/" target="_blank" class-name="el_nowwftO3rZ" className="el_nowwftO3rZ mobile-instagram">
                                <svg id="iconfinder_38-instagram_1161954" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                                    <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="3.135" cy="3.135" rx="3.135" ry="3.135" transform="translate(14.222 14.507)" fill="#1f1a16"></ellipse>
                                    <path id="Path_9" data-name="Path 9" d="M28.915,19.2H21.077a3.894,3.894,0,0,0-2.779,1,3.894,3.894,0,0,0-1,2.779v7.838a3.928,3.928,0,0,0,1.069,2.85,4.017,4.017,0,0,0,2.779,1h7.767a3.894,3.894,0,0,0,2.779-1,3.65,3.65,0,0,0,1.069-2.779V23.048a4.017,4.017,0,0,0-1-2.779A3.815,3.815,0,0,0,28.915,19.2ZM25,31.742a4.846,4.846,0,0,1,0-9.691,4.846,4.846,0,1,1,0,9.691Zm5.059-8.765a1.14,1.14,0,1,1,1.14-1.14A1.126,1.126,0,0,1,30.055,22.977Z"
                                        transform="translate(-7.638 -9.254)" fill="#1f1a16"></path>
                                    <path id="Path_10" data-name="Path 10" d="M21.2,5.2A17.5,17.5,0,1,0,38.7,22.7,17.487,17.487,0,0,0,21.2,5.2Zm9.319,21.626a5.639,5.639,0,0,1-1.565,4.055,5.536,5.536,0,0,1-3.984,1.494H17.216a5.536,5.536,0,0,1-3.984-1.494,5.36,5.36,0,0,1-1.565-4.055V19a5.265,5.265,0,0,1,5.549-5.549h7.825A5.246,5.246,0,0,1,30.519,19v7.825Z"
                                        transform="translate(-3.7 -5.2)" fill="#1f1a16"></path>
                                </svg>
                            </a>
                        </span>
                    </ul>
                    <button class-name="el_IobRNn5zmI" className="el_IobRNn5zmI" id="mobile-close" onClick={() => this.setState({ mobileMenu: false })}>
                        <div class-name="el_u6wRdkdjx2" className="el_u6wRdkdjx2"><svg xmlns="http://www.w3.org/2000/svg" width="25.456"
                            height="25.456" viewBox="0 0 25.456 25.456">
                            <g id="Group_336" data-name="Group 336" transform="translate(-1663.272 -701.272)">
                                <rect id="Rectangle_409" data-name="Rectangle 409" width="32" height="4" rx="2" transform="translate(1663.272 723.899) rotate(-45)"
                                    fill="#444"></rect>
                                <rect id="Rectangle_410" data-name="Rectangle 410" width="32" height="4" rx="2" transform="translate(1666.1 701.272) rotate(45)"
                                    fill="#444"></rect>
                            </g>
                        </svg>

                        </div>
                    </button>
                </div>
            </header>
        );
    }
}

export default Header;