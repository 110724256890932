import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Textarea from './fields/textarea';
import Select from './fields/select';
import rightChevron from '../../assets/svg/right-chevron.svg';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    meta: { touched, error },
    type
}) => (

        <Text
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            type={type}
            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

    const renderSelectField = ({
        input,
        placeholder,
        label,
        meta: { touched, error },
        children
    }) => (
    
            <Select
                placeholder={placeholder}
                label={label}
                errorText={touched && error}
                error={touched && error}
                {...input}
            >
                {children}
            </Select>
        )

class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }



    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(pristine, submitting);

        return (
            <form class-name="el_Ta14Yd8BZM" className="el_Ta14Yd8BZM" onSubmit={handleSubmit}>
                <div class-name="el_FsCMFzwIV5" className="el_FsCMFzwIV5 row">
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-6">
                        <Field
                            name="firstName"
                            component={renderTextField}
                            placeholder={"Ime".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-6">
                        <Field
                            name="lastName"
                            component={renderTextField}
                            placeholder={"Prezime".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-6">

                        <Field
                            name="phoneNumber"
                            component={renderTextField}
                            placeholder={"Telefon".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-6">
                        <Field
                            name="email"
                            component={renderTextField}
                            placeholder={"E-mail".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u select col-lg-6">
                        <Field
                            name="marka"
                            component={renderSelectField}
                            placeholder={"Marka vozila".translate(this.props.lang)}
                            validate={[required]}
                        >
                            <option value="ALFA ROMEO">ALFA ROMEO</option>
                            <option value="ARO">ARO</option>
                            <option value="ASTON MARTIN">ASTON MARTIN</option>
                            <option value="AUDI">AUDI</option>
                            <option value="BENTLEY">BENTLEY</option>
                            <option value="BMW">BMW</option>
                            <option value="BUICK">BUICK</option>
                            <option value="CADILLAC">CADILLAC</option>
                            <option value="CHEVROLET">CHEVROLET</option>
                            <option value="CITROEN">CITROEN</option>
                            <option value="DACIA">DACIA</option>
                            <option value="DAEWOO">DAEWOO</option>
                            <option value="DAIHATSU">DAIHATSU</option>
                            <option value="DODGE">DODGE</option>
                            <option value="F S O">F S O</option>
                            <option value="FERRARI">FERRARI</option>
                            <option value="FIAT">FIAT</option>
                            <option value="FORD">FORD</option>
                            <option value="GEELY">GEELY</option>
                            <option value="GREAT WALL MOTOR">GREAT WALL MOTOR</option>
                            <option value="HONDA">HONDA</option>
                            <option value="HYUNDAI">HYUNDAI</option>
                            <option value="INFINITI">INFINITI</option>
                            <option value="INNOCENTI">INNOCENTI</option>
                            <option value="ISUZU">ISUZU</option>
                            <option value="IVECO">IVECO</option>
                            <option value="JAGUAR">JAGUAR</option>
                            <option value="JEEP">JEEP</option>
                            <option value="KIA">KIA</option>
                            <option value="LADA">LADA</option>
                            <option value="LANCIA">LANCIA</option>
                            <option value="LAND ROVER">LAND ROVER</option>
                            <option value="LEXUS">LEXUS</option>
                            <option value="LINKOLN">LINKOLN</option>
                            <option value="LOTUS">LOTUS</option>
                            <option value="MAHINDRA">MAHINDRA</option>
                            <option value="MAPLE">MAPLE</option>
                            <option value="MARUTI">MARUTI</option>
                            <option value="MASSERATI">MASSERATI</option>
                            <option value="FERRARI">FERRARI</option>
                            <option value="MATRA">MATRA</option>
                            <option value="MAZDA">MAZDA</option>
                            <option value="MERCEDES">MERCEDES</option>
                            <option value="MG (ROVER)">MG (ROVER)</option>
                            <option value="MINI (BMW)">MINI (BMW)</option>
                            <option value="MITSUBISHI">MITSUBISHI</option>
                            <option value="MOSKVIC">MOSKVIC</option>
                            <option value="NISSAN">NISSAN</option>
                            <option value="OLDSMOBILE">OLDSMOBILE</option>
                            <option value="OLTICT">OLTICT</option>
                            <option value="OPEL">OPEL</option>
                            <option value="PERODUA">PERODUA</option>
                            <option value="PEUGEOT">PEUGEOT</option>
                            <option value="PONTIAC">PONTIAC</option>
                            <option value="PORSCHE">PORSCHE</option>
                            <option value="PROTON">PROTON</option>
                            <option value="RENAULT">RENAULT</option>
                            <option value="ROLLS ROYCE">ROLLS ROYCE</option>
                            <option value="ROVER">ROVER</option>
                            <option value="SAAB">SAAB</option>
                            <option value="SEAT">SEAT</option>
                            <option value="SIMCA">SIMCA</option>
                            <option value="ŠKODA">ŠKODA</option>
                            <option value="SMART">SMART</option>
                            <option value="SSANGYONG">SSANGYONG</option>
                            <option value="STEYR DAIMLER PUCH">STEYR DAIMLER PUCH</option>
                            <option value="SUBARU">SUBARU</option>
                            <option value="SUZUKI">SUZUKI</option>
                            <option value="TAM">TAM</option>
                            <option value="TATA">TATA</option>
                            <option value="TATRA">TATRA</option>
                            <option value="TOYOTA">TOYOTA</option>
                            <option value="TRIUMPH">TRIUMPH</option>
                            <option value="UAZ">UAZ</option>
                            <option value="VAUXHALL">VAUXHALL</option>
                            <option value="VOLGA">VOLGA</option>
                            <option value="VOLKSWAGEN">VOLKSWAGEN</option>
                            <option value="VOLVO">VOLVO</option>
                            <option value="YUGO">YUGO</option>
                            <option value="ZASTAVA">ZASTAVA</option>
                            <option value="ZAZ-ZAPORAC">ZAZ-ZAPORAC</option>
                        </Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-6">
                        <Field
                            name="tipVozila"
                            component={renderTextField}
                            placeholder={"Tip vozila".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u select col-lg-6">
                        <Field
                            name="oblikKaroserije"
                            component={renderSelectField}
                            placeholder={"Oblik karoserije".translate(this.props.lang)}
                            validate={[required]}
                        >
                            <option value="Kupe">{'Kupe'.translate(this.props.lang)}</option>
                            <option value="Limuzina">{'Limuzina'.translate(this.props.lang)}</option>
                            <option value="Karavan">{'Karavan'.translate(this.props.lang)}</option>
                            <option value="Produžena limuzina">{'Produžena limuzina'.translate(this.props.lang)}</option>
                            <option value="Višenamjenski zatvoreni putnički automobil">{'Višenamjenski zatvoreni putnički automobil'.translate(this.props.lang)}</option>
                            <option value="Specijalni zatvoreni putnički automobil">{'Specijalni zatvoreni putnički automobil'.translate(this.props.lang)}</option>
                            <option value="Kupe kabriolet">{'Kupe kabriolet'.translate(this.props.lang)}</option>
                            <option value="Limuzina kabriolet">{'Limuzina kabriolet'.translate(this.props.lang)}</option>
                            <option value="Sportski automobil">{'Sportski automobil'.translate(this.props.lang)}</option>
                        </Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u select col-lg-6">
                        <Field
                            name="brojVrata"
                            component={renderSelectField}
                            placeholder={"Broj vrata".translate(this.props.lang)}
                            validate={[required]}
                        >
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u select col-lg-6">
                        <Field
                            name="vrstaMjenjaca"
                            component={renderSelectField}
                            placeholder={"Vrsta mjenjača".translate(this.props.lang)}
                            validate={[required]}
                        >
                            <option value="Manuelni">{"Manuelni".translate(this.props.lang)}</option>
                            <option value="Automatik">{"Automatik".translate(this.props.lang)}</option>
                            <option value="Poluautomatik">{"Poluautomatik".translate(this.props.lang)}</option>
                        </Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-6">
                        <Field
                            name="motorPower"
                            component={renderTextField}
                            placeholder={"Snaga motora (kW)".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-6">
                        <Field
                            name="motorVolume"
                            component={renderTextField}
                            placeholder={"Zapremina motora (m3=cbm)".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u select col-lg-6">
                        <Field
                            name="pogon"
                            component={renderSelectField}
                            placeholder={"Pogon".translate(this.props.lang)}
                            validate={[required]}
                        >
                            <option value="Prednji">{'Prednji'.translate(this.props.lang)}</option>
                            <option value="Zadnji">{'Zadnji'.translate(this.props.lang)}</option>
                            <option value="Sva četiri">{'Sva četiri'.translate(this.props.lang)}</option>
                        </Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u select col-lg-6">
                        <Field
                            name="vrstaGoriva"
                            component={renderSelectField}
                            placeholder={"Vrsta goriva".translate(this.props.lang)}
                            validate={[required]}
                        >
                            <option value="Dizel">{'Dizel'.translate(this.props.lang)}</option>
                            <option value="Benzin">{'Benzin'.translate(this.props.lang)}</option>
                            <option value="Plin">{'Plin'.translate(this.props.lang)}</option>
                            <option value="Hibrid">{'Hibrid'.translate(this.props.lang)}</option>
                            <option value="Elektro">{'Elektro'.translate(this.props.lang)}</option>
                        </Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u select col-lg-6">
                        <Field
                            name="emisioniStandard"
                            component={renderSelectField}
                            placeholder={"Emisioni standard".translate(this.props.lang)}
                            validate={[required]}
                        >
                            <option value="Euro 5">EURO 5</option>
                            <option value="Euro 6">EURO 6</option>
                        </Field>
                    </div>
                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-12">
                        <Field
                            name="datumRegistraijce"
                            component={renderTextField}
                            placeholder={"Datum prve registracije".translate(this.props.lang)}
                            validate={[required]}
                        ></Field>
                    </div>

                    <div class-name="el_lBf1eO6x7u" className="el_lBf1eO6x7u col-lg-12">

                        {this.props.loading ?
                            null
                            :
                            <button className="button">{'POŠALJI'.translate(this.props.lang)}</button>
                        }
                    </div>
                    
    
                </div>
            </form>

        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
