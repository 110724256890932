import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from '../components/slider';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import Image from '../components/image';

import downChevron from '../assets/svg/down-chevron.svg';




class ServicePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


    }

    componentDidUpdate(prevProps){
        if (prevProps[0].location.pathname != this.props[0].location.pathname){
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
    
        }
    }



    render() {

        return (
            <div>
                <section class-name="el_MWt_UkcEbY" className="el_MWt_UkcEbY"><div class-name="el_nxSTNAI73a" className="el_nxSTNAI73a container"><div class-name="el_xXSSMBLR64" className="el_xXSSMBLR64 row"><div class-name="el_CEQDddamsC" className="el_CEQDddamsC col-lg-12"><h1 class-name="el_36aob6dvPA" className="el_36aob6dvPA">{'Usluge'.translate(this.props.lang)}</h1>

                    <ul class-name="el_P3NocKyAEA" className="el_P3NocKyAEA">

                        <li class-name="el_Jl1PjCRvxx" className="el_Jl1PjCRvxx">{'Početna'.translate(this.props.lang)}</li>
                        <li class-name="el_Jl1PjCRvxx" className="el_Jl1PjCRvxx">{'Usluge'.translate(this.props.lang)}</li><li class-name="el_Jl1PjCRvxx" className="el_Jl1PjCRvxx">{Object.translate(this.state.data, 'name', this.props.lang)}</li></ul>

                </div>
                    <div class-name="el_9k4VHh6Eh0" className="el_9k4VHh6Eh0 col-lg-4"><div class-name="el_3g_n_D46oP" className="el_3g_n_D46oP">
                        <h3>{'Navigacija'.translate(this.props.lang)}</h3>
                        <ul class-name="el_dhzbJRarU5" className="el_dhzbJRarU5">
                            {
                               (this.state.pages && ( (typeof window !== 'undefined' && window.innerWidth < 768 && this.state.menu) || (typeof window == 'undefined' || (typeof window !== 'undefined' && window.innerWidth >= 768) ) ) ) ? this.state.pages.map((item, idx) => {
                                    console.log(item)
                                    return (
                                        <li class-name="el_KFq7vy1DNF" className="el_KFq7vy1DNF">
                                            <Link lang={this.props.lang} className={ this.props[0].match.params.alias == Object.translate(item, 'alias', this.props.lang) ? "el_WUkRddeBDX service-active" : "el_WUkRddeBDX"} to={`/usluge/${Object.translate(item, 'alias', this.props.lang)}`}>{Object.translate(item, 'name', this.props.lang)}</Link>
                                            {
                                                item.children && item.children.length ?
                                                    <ul class-name="el_1KXowxkT4H" className="el_1KXowxkT4H">
                                                        {
                                                            item.children.map((item1, idx1) => {
                                                                console.log(item1)
                                                                return (
                                                                    <li class-name="el_31GfFwpZVW" className="el_31GfFwpZVW">
                                                                        <Link lang={this.props.lang} to={`/usluge/${Object.translate(item, 'alias', this.props.lang)}/${Object.translate(item1, 'alias', this.props.lang)}`} className={this.props[0].match.params.alias == Object.translate(item1, 'alias', this.props.lang) ? "el_pPJeX0aDmo service-active" : "el_pPJeX0aDmo" }>{Object.translate(item1, 'name', this.props.lang)}</Link>
                                                                    </li>

                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    :
                                                    null

                                            }
                                        </li>

                                    )
                                })

                                :

                                null
                            }
                        </ul>
                        <div class-name="el_j4lMmi33aE" className="el_j4lMmi33aE">
                            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
                                <g id="Group_318" data-name="Group 318" transform="translate(-897 -2255)">
                                    <rect id="Rectangle_49" data-name="Rectangle 49" width="4" height="4" transform="translate(897 2255)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_50" data-name="Rectangle 50" width="4" height="4" transform="translate(913 2255)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_51" data-name="Rectangle 51" width="4" height="4" transform="translate(929 2255)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_52" data-name="Rectangle 52" width="4" height="4" transform="translate(945 2255)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_53" data-name="Rectangle 53" width="4" height="4" transform="translate(961 2255)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_49-2" data-name="Rectangle 49" width="4" height="4" transform="translate(897 2271)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_50-2" data-name="Rectangle 50" width="4" height="4" transform="translate(913 2271)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_51-2" data-name="Rectangle 51" width="4" height="4" transform="translate(929 2271)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_52-2" data-name="Rectangle 52" width="4" height="4" transform="translate(945 2271)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_53-2" data-name="Rectangle 53" width="4" height="4" transform="translate(961 2271)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_49-3" data-name="Rectangle 49" width="4" height="4" transform="translate(897 2287)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_50-3" data-name="Rectangle 50" width="4" height="4" transform="translate(913 2287)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_51-3" data-name="Rectangle 51" width="4" height="4" transform="translate(929 2287)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_52-3" data-name="Rectangle 52" width="4" height="4" transform="translate(945 2287)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_53-3" data-name="Rectangle 53" width="4" height="4" transform="translate(961 2287)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_49-4" data-name="Rectangle 49" width="4" height="4" transform="translate(897 2303)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_50-4" data-name="Rectangle 50" width="4" height="4" transform="translate(913 2303)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_51-4" data-name="Rectangle 51" width="4" height="4" transform="translate(929 2303)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_52-4" data-name="Rectangle 52" width="4" height="4" transform="translate(945 2303)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_53-4" data-name="Rectangle 53" width="4" height="4" transform="translate(961 2303)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_49-5" data-name="Rectangle 49" width="4" height="4" transform="translate(897 2319)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_50-5" data-name="Rectangle 50" width="4" height="4" transform="translate(913 2319)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_51-5" data-name="Rectangle 51" width="4" height="4" transform="translate(929 2319)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_52-5" data-name="Rectangle 52" width="4" height="4" transform="translate(945 2319)" fill="#00aeea"></rect>
                                    <rect id="Rectangle_53-5" data-name="Rectangle 53" width="4" height="4" transform="translate(961 2319)" fill="#00aeea"></rect>
                                </g>
                            </svg>

                        </div>
                        <button onClick={() => this.setState({
                            menu: !this.state.menu
                        })} class-name="el_sWOyuC5bUf" className="el_sWOyuC5bUf"><svg xmlns="http://www.w3.org/2000/svg" width="16.828" height="9.414" viewBox="0 0 16.828 9.414">
                                <path id="iconfinder_chevron-down_2561356" d="M6,9l7,7,7-7" transform="translate(-4.586 -7.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                            </svg></button>
                    </div></div>
                    <div class-name="el_yqLmUHYIHg" className="el_yqLmUHYIHg col-lg-8">
                    <Row>
                    {
                                this.state.data && this.state.data.content && this.state.data.content.map((item, idx) => {
                                    if (item.type == 'image') {
                                        return (
                                            <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section">
                                                <img src={item.value} />
                                            </Col>
                                        )
                                    } else {
                                        return (
                                            <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section" dangerouslySetInnerHTML={{ __html: item.value && item.value[this.props.lang] }}>
                                            </Col>
                                        )
                                    }
                                })
                            }


                    </Row>
                    </div>
                    </div></div></section>
            </div>
        );
    }
}

export default Page(ServicePage);